<template>
  <div class="customer">
    <div class="breadcrumb">{{ $t("lead") }}</div>
    <div class="customers">
      <tool-bar @change-view="handleChangeView" :current="currentView">
        <template v-slot:other>
          <n-button
            v-if="displayAssign"
            @click="handleReceive"
            :disable="disableReceiveBtn"
          >
            <!-- 分配给业务员 -->
            {{ $t("assign") }}
          </n-button>
          <!-- v-if="displayReceive" :disable="disableReceiveBtn"-->
          <n-button v-if="$route.query.menu == 'publicLead'" @click="handleReceive" :disable="disableReceiveBtn">
            {{ $t("receive") }}
          </n-button>
		  <n-button v-if="$route.query.menu == 'myInquiry'" @click="handleReceive" :disable="disableReceiveBtn">
		    {{ $t("giveUp") }}
		  </n-button>
        </template>
        <template v-slot:create>
          <i />
        </template>
        <n-button v-if="displaySaveBtn" @click="handleSave">
          {{ $t("save") }}
        </n-button>
        <n-button v-if="displayEditBtn" @click="handleEdit">
          {{ $t("edit") }}
        </n-button>
        <!-- <n-button v-if="displaySaveBtn" @click="handleSave">Save</n-button> -->
        <!-- <n-button v-if="displayEditBtn" @click="handleEdit">Edit</n-button> -->
      </tool-bar>
      <div class="customer-view">
        <!-- <a-spin tip="Loading..." :spinning="loading"> -->
        <a-empty v-if="empty" />
        <div class="card-view" v-if="cardView">
          <div class="customer-cards">
            <customer-card
              v-for="customer in customers"
              :key="customer.id"
              :customer="customer"
              @change="handleCardPageChange"
              @click="handleClickCard"
              @edit="handleEdit"
            ></customer-card>
          </div>
          <a-pagination
            class="pagination"
            size="small"
            v-model="currentPage"
            @change="handleTableChange"
            :pageSize="pagination.pageSize"
            show-less-items
            :total="pagination.total"
          />
        </div>
        <n-table
          :loading="loading"
          v-if="tableView"
          :data="customers"
          :columns="columns"
          :pagination="pagination"
          @change="handleTableChange"
          @select="handleSelectLead"
          @click="handleToggleView"
        ></n-table>
        <div class="detail-view p-100" v-if="detailView">
          <customer-detail
            :customer="record"
            :edit="edit"
            @change="handleChange"
          ></customer-detail>
        </div>
        <!-- </a-spin> -->
      </div>
    </div>
    <div class="Notification" v-if="notification">
      <p>您成功添加该线索</p>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import CustomerCard from "@/components/card/CustomerCard.vue";
import ToolBar from "../tool-bar/index.vue";
import NTable from "../table/index.vue";
import CustomerDetail from "../customer-detail/index.vue";
import NButton from "../tool-bar/button/index.vue";
import api from "@/api";
export default {
  data() {
    return {
      notification: false,
      currentPage: 1,
      currentView: "tableView",
      loading: false,
      customers: [],
      leadIds: [],
      record: {
        last_name: "",
        first_name: "",
        enterprise: "",
        address: "",
        street: "",
        street2: "",
        city: "",
        country: "",
        tel: "",
        user_mobile: "",
        user_email: "",
        site: "",
        tax: "",
        tag: "",
      },
      edit: false,
      user: {
        role: "admin",
      },
      createCustomer: {},
      pagination: {
        pageSize: 10,
        total: 0,
        current: 1,
        page: 1,
      },
      columns: [
        {
          title: this.$t("leadName"),
          dataIndex: "enterprise",
          key: "id",
        },
        {
          title: this.$t("enterprise"),
          dataIndex: "enterprise",
          key: "enterprise",
        },
        {
          title: this.$t("contact"),
          dataIndex: "first_name",
          customRender: (text, row) => {
            return <span>{text + row.last_name}</span>;
          },
        },
        {
          title: this.$t("salesman"),
          key: "service_name",
          dataIndex: "service_name",
        },
        {
          title: this.$t("country"),
          dataIndex: "country",
          key: "country",
        },
        {
          title: this.$t("lastMessage"),
          dataIndex: "time",
          key: "time",
        },
        {
          title: this.$t("tag"),
          dataIndex: "tag",
          key: "tag",
          customRender: (text) => {
            return text && <a-tag color={"#63d8bb"}>{text}</a-tag>;
          },
        },
      ],
    };
  },
  watch: {
    $route: {
      handler() {
        this.init();
      },
      deep: true,
    },
  },
  computed: {
    ...mapState("jiyang/user", ["info"]),
    displayAssign() {
      return (
        this.isAdmin &&
        this.$route.query.menu == "publicInquiry" &&
        this.record.enterprise.trim() != ""
      );
    },
    isAdmin() {
      return this.user.role == "admin";
    },
    isSeller() {
      return this.user.role == "seller";
    },
    disable() {
      return this.record.enterprise.trim() == "" || this.record == null;
    },

    empty() {
      return this.customers.length == 0 && this.currentView == "cardView";
    },
    createView() {
      return this.currentView === "createView";
    },
    cardView() {
      return this.currentView === "cardView" && this.customers.length > 0;
    },
    tableView() {
      return this.currentView === "tableView";
    },
    detailView() {
      return this.currentView === "detailView";
    },
    displayReceive() {
      return this.$route.query.menu == "publicLead";
    },
    disableReceiveBtn() {
      return this.leadIds.length == 0;
    },
    displaySaveBtn() {
      return (
        (this.currentView === "detailView" && this.edit) ||
        this.currentView == "createView"
      );
    },
    displayEditBtn() {
      return this.currentView === "detailView" && !this.edit;
    },
  },
  created() {
    this.init();
  },
  methods: {
    ...mapMutations("jiyang/enquiry", ["setPublicEnquiry"]),
    handleAssign() {},
    handleCardPageChange() {},
    async handleReceive() {
      if (this.$route.query.menu == "publicInquiry") {
        //公海询盘
        this.batch(api.receivePublicInquiry, this.getPublicInquiry, 2);
        return;
      }
	  
      this.notification = true;
      setTimeout(() => {
        this.notification = true;
        setTimeout(() => {
          this.notification = false;
        }, 2000);
      }, 1000);
	  
	  if(this.$route.query.menu == "myInquiry"){
		  this.pagination.is_owner = 1;
		  this.batch(api.putClueToSea, this.getClueList,2);
	  }
	  if(this.$route.query.menu == "publicLead"){
		  this.batch(api.receiveClue, this.getClueList);
	  }
    },
    async batch(update, get, list_type = 1) {
      let leads = this.leadIds;
      if (!leads) {
        return;
      }
      //公海询盘
      let type = 1;
      let salesman_id = this.info.id;
      let state = 3;
      for (let idx = 0; idx < leads.length; idx++) {
        try {
          if (list_type === 2) {
            await update({ id: leads[idx] });
          } else {
            await update({ customer_id: leads[idx], type, state, salesman_id });
          }
        } catch (e) {
        } finally {
		  await get();
          this.loading = false;
          this.leadIds = [];
        }
      }
    },
    configLeadColumn() {
      this.columns = [
        /* {
          title: this.$t("leadName"),
          dataIndex: "user_email",
          key: "id",
          // fixed: "left",
          // width: "150px",
        }, */
        {
          title: this.$t("enterprise"),
          dataIndex: "enterprise",
          key: "enterprise",
          // width: "150px",
        },
        {
          title: this.$t("contact"),
          dataIndex: "first_name",
          width: "100px",
          customRender: (text, row) => {
            return <span>{"" + row.first_name + row.last_name}</span>;
          },
        },
		{
		  title: this.$t("contact"),
		  key: "user_mobile",
		  dataIndex: "user_mobile",
		  // width: "100px",
		},
		{
		  title: this.$t("email"),
		  key: "user_email",
		  dataIndex: "user_email",
		  // width: "100px",
		},
       /* {
          title: this.$t("salesman"),
          key: "service_name",
          dataIndex: "service_name",
          // width: "100px",
        }, */
        // {
        //   title: this.$t("country"),
        //   dataIndex: "country",
        //   key: "country",
        // },
        {
          title: this.$t("lastMessage"),
          dataIndex: "create_time",
          key: "create_time",
          // width: "150px",
        },
        /* {
          title: this.$t("tag"),
          dataIndex: "tag",
          key: "tag",
          customRender: (text) => {
            return text && <a-tag color={"#63d8bb"}>{text}</a-tag>;
          },
        }, */
      ];
	  
	  if(this.$route.query.menu == 'myInquiry'){
		  this.columns.push({
		  title: this.$t("operate"),
		  // dataIndex: "create_user_name",
		  key: "operate",
		  width: "200px",
		  customRender: (text, row) => {
		    return (
		      <div>
				<i
				  class="createCustomer"
				  style="margin-right:20px;font-size:20px"
				  onClick={(e) => this.handleCreateCustomer(row)}
				/>
		      </div>
		    );
		  },
		})
	  }
    },
    configInquiryColumn() {
      this.columns = [
        {
          title: this.$t("sn"),
          dataIndex: "sn",
          key: "sn",
          // fixed: "left",
        },
        {
          title: this.$t("enterprise"),
          customRender: (text, row) => {
            return <span>{row.customer && row.customer.enterprise}</span>;
          },
          // width: "100px",
        },
        // },
        // {
        //   title: "公司名称",
        //   dataIndex: "customer",
        //   customRender: (text, row) => {
        //     return <span>{row.customer && row.customer.enterprise}</span>;
        //   },
        // },
        {
          title: this.$t("contact"),
          // width: "100px",
          dataIndex: "customer",
          customRender: (text, row) => {
            return <span>{row.customer && row.customer.first_name}</span>;
          },
        },
        {
          title: this.$t("salesman"),
          key: "service_name",
          dataIndex: "service_name",
          // width: "100px",
        },
        // {
        //   title: this.$t("country"),
        //   dataIndex: "country",
        //   key: "country",
        //   width: "100px",
        // },
        {
          title: this.$t("lastMessage"),
          dataIndex: "create_time",
          key: "create_time",
          // width: "150px",
        },
        {
          title: this.$t("tag"),
          dataIndex: "tag",
          key: "tag",
          customRender: (text) => {
            return text && <a-tag color={"#63d8bb"}>{text}</a-tag>;
          },
        },
      ];
    },
    getClueList() {
      this.configLeadColumn();
      this.useRequest(api.getClueList);
    },
    async getPublicInquiry() {
      this.configInquiryColumn();
      const { data } = await this.useRequest(api.getPublicInquiry);
      this.setPublicEnquiry(data);
    },
    handleSelectLead(leads) {
      this.leadIds = leads;
    },
    handleEdit(customer) {
      this.edit = true;
      this.record = customer;
    },
    init() {
      let type = this.info.user_type;
      let roleMap = {
        1: "admin",
        2: "seller",
      };
      this.user.role = roleMap[type];
      this.currentView = "tableView";
      this.cleanPageInfo();
      this.cleanRecord();
      this.switchApi();
    },
    switchApi() {
      const query = this.$route.query.menu;
      switch (query) {
        case "publicInquiry":
          this.getPublicInquiry();
          break;
        case "publicLead":
		  this.pagination.is_owner = 0;
          this.getClueList();
          break;
        default:
		  this.pagination.is_owner = 1;
          this.getClueList();
      }
    },
    handleChange(customer) {
      this.record = customer;
    },
    handleTableChange({ pagination, filters, sorter }) {
      this.pagination = pagination;
      this.switchApi();
    },
    async handleSave() {
      this.edit = false;
      if (this.record.user_mobile.trim() == "") {
        this.$message.error("手机号不能为空");
        return;
      }
      if (this.record.enterprise.trim() == "") {
        this.$message.error("企业名称不能空");
        return;
      }
      this.loading = true;
      try {
        await api.createClue(customer);
        this.cleanRecord();
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
        this.init();
        this.currentView = "tableView";
      }
    },
    handleToggleView(record) {
      // this.currentView = "detailView";
      // this.record = record;
    },
    cleanPageInfo() {
      this.pagination = {
        pageSize: 9,
        total: 0,
        page: 1,
        current: 1,
      };
    },
    cleanRecord() {
      this.record = {
        last_name: "",
        first_name: "",
        enterprise: "",
        address: "",
        street: "",
        street2: "",
        city: "",
        country: "",
        tel: "",
        user_mobile: "",
        user_email: "",
        site: "",
        tax: "",
        tag: "",
      };
    },
    async useRequest(request) {
      this.loading = true;
      const res = await request(this.pagination);
      this.loading = false;
      if (!res) {
        this.customers = [];
        this.cleanPageInfo();
        return;
      }
      const { total, per_page, current_page, data } = res;
      this.customers = data;
      this.pagination = {
        pageSize: per_page,
        total: total,
        page: current_page,
        current: current_page,
      };
      return res;
    },
    handleChangeView(view) {
      this.currentView = view;
    },
    handleClickCard(customer) {
      this.currentView = "detailView";
      this.record = customer;
    },
	handleCreateCustomer(data){
		this.$router.push({path:'/crm/customer?menu=myCustomer',query:data})
	}
  },
  components: {
    CustomerCard,
    ToolBar,
    NTable,
    CustomerDetail,
    NButton,
  },
};
</script>

<style lang="scss">
.Notification {
  position: fixed;
  left: 20px;
  bottom: 40px;
  width: 200px;
  height: 40px;
  line-height: 40px;
  background: #fff;
  z-index: 99999;
  padding: 0 10px;
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.2);
}
.createCustomer{
	background-image: url('../../../assets/image/createCustomer.png');
	width: 20px;
	height: 20px;
	display: block;
	background-repeat: round;
}
</style>
